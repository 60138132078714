import { PROD_BASE_TOKEN, PROD_BASE_URL } from "../constants/endpoints";

export const request = async (path, method = "GET", data, setLoading) => {
  setLoading(true)
  const controller = new AbortController();
  const signal = controller.signal;
  let url = `${PROD_BASE_URL}${path}`;
  if (method === "GET" && !!data) {
    const params = new URLSearchParams(data);
    url += `?${params.toString()}`;
  }
  return fetch(url, {
    method,
    headers: {
      Authorization: `Bearer ${PROD_BASE_TOKEN}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: method !== "GET" && !!data ? JSON.stringify(data) : undefined,
    signal: signal,
  })
    .then(async (response) => {
      return await response.json();
    }).finally(() => setLoading(false))
};