import "./App.css";
import { useEffect } from "react";
import { decodeFromB64, getParameterByName } from "./easy";
import { useEGCRContext } from "./context/egcr-context";

const decodedNumber = decodeFromB64(getParameterByName("q")).replace(
  "number=",
  ""
);

function Receipt() {
  const {loading, fetchEgcr, getEgcrLink} = useEGCRContext()
  useEffect(() => {
    fetchEgcr(decodedNumber).then((response) => {
      if(!!response && response.status === 200 && response.data) {
        const { egcrLink } = response.data; //branchCode was removed
        getEgcrLink(egcrLink, decodedNumber)
      }
    })
  }, []);

  return (
    <main className="container">
      {loading && (
        <div className="loader-container">
          {" "}
          <div className="loader"></div>
          <h2>Loading receipt. Please wait...</h2>
        </div>
      )}
    </main>
  );
}

export default Receipt;
