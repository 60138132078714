import { createContext, useContext, useState } from "react";
import { request } from "./request";
import { PROD_DVLA_EGCR_API_KEY, PROD_EGCR_API_KEY } from "../constants/endpoints";

const EGCRContext = createContext(undefined)
const EGCRProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const fetchEgcr = (egcr) => {
    const decoded = egcr.replace("&org=dvla", "")
    return request(decoded, "GET", null, setLoading)
  }

  const getEgcrLink = (egcrLink, decodedNumber) => {
    setLoading(true)
    const containsDvla = decodedNumber.toLowerCase().includes("dvla")
    return fetch(`${egcrLink}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": containsDvla ? PROD_DVLA_EGCR_API_KEY : PROD_EGCR_API_KEY,
      },
    })
      .then(async (res) => {
        const egcrData = await res.blob();
        if (!!egcrData) {
          const url = URL.createObjectURL(egcrData);
          window.open(url, "_self");
        } else {
          console.log("Could not get file");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (<EGCRContext.Provider value={{
    loading,
    fetchEgcr,
    getEgcrLink,
  }}>
    {children}
  </EGCRContext.Provider>)
}

export const useEGCRContext = () => useContext(EGCRContext)
export default EGCRProvider